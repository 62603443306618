/*table, th {
  font-size: 80% !important;
}*/

.table#strategies > tbody > tr > td:nth-child(2) {
  background-color:white;
  position:sticky;
  left:28px;
}

.table#strategies > thead > tr >th:nth-child(1) {
  background-color:white;
  position:sticky;
  left:0px;
}

.table#strategies > thead > tr >th:nth-child(2) {
  background-color:white;
  position:sticky;
  left:28px;
}
  
th {
  cursor: pointer;
}

td:first-child {
  background-color:white;
}

th:hover {
  background-color: #e8e7e3;
}

.table#strategies > tbody > tr > td {
  padding: 1px 7px !important;
}

.table > thead > tr > th {
  border: 2px solid #dee2e6 !important;
}

th:first-child, td:first-child {
  position:sticky;
  left:0px;
}

.table > thead > tr >th:nth-child(1) {
  background-color:white !important;
  position:sticky;
  left:0px;
}


.table > tbody > tr > td:nth-child(1) {
  background-color:white !important;
  position:sticky;
  left:0px;
}

.table.risk-calcultator > tbody > tr > td {
  padding: 1px 7px !important;
}

td {
  text-align: center;
}

.main-panel > .content {
  padding: 0 17px 17px !important;
}