.table#pl>thead>tr>th:nth-child(1) {
  background-color: white;
  position: sticky;
  left: 0px;
}

.table#pl>tbody>tr>td:nth-child(2) {
  background-color: white;
  position: sticky;
  left: 0px;
}

.table#pl>thead>tr>th:nth-child(2) {
  background-color: white;
  position: sticky;
  left: 97px;
}

.table#pl>tbody>tr>td:nth-child(2) {
  background-color: white;
  position: sticky;
  left: 97px;
}

.table#pl>thead>tr>th:nth-child(3) {
  background-color: white;
  position: sticky;
  left: 134px;
}

.table#pl>tbody>tr>td:nth-child(3) {
  background-color: white;
  position: sticky;
  left: 134px;
}

.table.profits>tbody>tr>td {
  background-color: white;
}

/*.table.profits, #pl {
  font-size: 90%;
}*/

.table>tbody>tr>td {
  padding: 1px !important;
}

.table > thead > tr > th {
  font-size: 12px !important;
}