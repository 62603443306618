.select-search {
    text-align: center;
}

.select-search {
    width: 100%;
}

h4 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.active-signal{
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
}

.inactive-signal {
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
}
